enum ManualLinkKey {
    HomePage = 'HomePage',

    // Advertiser
    AdvertiserForm = 'AdvertiserForm',

    // Advertiser / Tracking Pages
    AdvertiserTrackingPages = 'AdvertiserTrackingPages',
    AdvertiserTrackingPageGeneralSettings = 'AdvertiserTrackingPageGeneralSettings',
    AdvertiserTrackingPageLinks = 'AdvertiserTrackingPageLinks',

    // Tracking Spots / Tracking Spots
    TrackingPageTrackingSpots = 'TrackingPageTrackingSpots',
    TrackingPageTrackingSpotGeneralSettings = 'TrackingPageTrackingSpotGeneralSettings',

    // BannerPool
    BannerPools = 'BannerPools',
    BannerPoolForm = 'BannerPoolForm',
    BannerPoolBannerForm = 'BannerPoolBannerForm',

    // Order
    OrderCreationGeneralSettings = 'OrderCreationGeneralSettings',
    OrderSettingsGeneralSettings = 'OrderSettingsGeneralSettings',
    OrderBudget = 'OrderBudget',
    OrderRuntimeBudget = 'OrderRuntimeBudget',
    OrderEvenDelivery = 'OrderEvenDelivery',

    // Campaign
    RedirectCampaignGeneralSettings = 'RedirectCampaignGeneralSettings',
    CampaignTypeGeneralSettingsRuntime = 'CampaignTypeGeneralSettingsRuntime',
    CampaignBudget = 'CampaignBudget',
    CampaignEvenDelivery = 'CampaignEvenDelivery',
    CampaignOptimization = 'CampaignOptimization',
    ViewabilityMeasurement = 'ViewabilityMeasurement',

    // Campaign / Bulk Edit
    CampaignBulkEdit = 'CampaignBulkEdit',

    // Campaign / Third Party Cost
    CampaignThirdPartyCostForm = 'ThirdPartyCostForm',

    // Campaign / Deals
    CampaignDeals = 'CampaignDeals',

    // Campaign / Bid Factors
    CampaignBidFactors = 'CampaignBidFactors',

    // Campaign / Tracking Pages
    CampaignTrackingPages = 'CampaignTrackingPages',

    // Banner
    BannerNativeType = 'BannerNativeType',
    BannerNativeSpecs = 'BannerNativeSpecs',
    BannerImageType = 'BannerImageType',
    BannerRedirectType = 'BannerRedirectType',
    BannerRichMediaType = 'BannerRichMediaType',
    BannerVastType = 'BannerVastType',
    BannerVastWrapperType = 'BannerVastWrapperType',
    BannerSwitchInType = 'BannerSwitchInType',
    BannerSwitchInXxlType = 'BannerSwitchInXxlType',
    BannerSwitchInXxlAnimatedType = 'BannerSwitchInXxlAnimatedType',
    BannerHtml5Type = 'BannerHtml5Type',
    BannerAudioType = 'BannerAudioType',
    BannerVideoType = 'BannerVideoType',
    BannerDoohType = 'BannerDoohType',
    BannerBumperAdType = 'BannerBumperAdType',
    BannerDeprecatedAtvSpotType = 'BannerAtvSpotType',
    BannerPtvAdSpotType = 'BannerPtvAdSpotType',

    // Targeting
    TargetingTvTargetingCampaign = 'TargetingTvTargetingCampaign',
    TargetingTvTargetingBanner = 'TargetingTvTargetingBanner',
    TargetingTaggingCampaign = 'TargetingTaggingCampaign',
    TargetingTaggingBanner = 'TargetingTaggingBanner',
    TargetingSomTargetingCampaign = 'TargetingSomTargetingCampaign',
    TargetingSomTargetingBanner = 'TargetingSomTargetingBanner',
    TargetingFrequencyCappingCampaign = 'TargetingFrequencyCappingCampaign',
    TargetingFrequencyCappingBanner = 'TargetingFrequencyCappingBanner',
    TargetingGraphCappingCampaign = 'TargetingGraphCappingCampaign',
    TargetingGraphCappingBanner = 'TargetingGraphCappingBanner',
    TargetingAnyIdFrequencyCampaign = 'TargetingAnyIdFrequencyCampaign',
    TargetingAnyIdFrequencyBanner = 'TargetingAnyIdFrequencyBanner',
    TargetingProfileCampaign = 'TargetingProfileCampaign',
    TargetingProfileBanner = 'TargetingProfileBanner',
    TargetingOperatingSystemCampaign = 'TargetingOperatingSystemCampaign',
    TargetingOperatingSystemBanner = 'TargetingOperatingSystemBanner',
    TargetingBrowserCampaign = 'TargetingBrowserCampaign',
    TargetingBrowserBanner = 'TargetingBrowserBanner',
    TargetingAudioCampaign = 'TargetingAudioCampaign',
    TargetingAudioBanner = 'TargetingAudioBanner',
    TargetingTimeCampaign = 'TargetingTimeCampaign',
    TargetingTimeBanner = 'TargetingTimeBanner',
    TargetingAuctionTypeCampaign = 'TargetingAuctionTypeCampaign',
    TargetingAuctionTypeBanner = 'TargetingAuctionTypeBanner',
    TargetingSspCampaign = 'TargetingSspCampaign',
    TargetingSspAvailableSsps = 'TargetingSspAvailableSsps',
    TargetingPlattformCampaign = 'TargetingPlattformCampaign',
    TargetingPlattformBanner = 'TargetingPlattformBanner',
    TargetingUrlCampaign = 'TargetingUrlCampaign',
    TargetingUrlBanner = 'TargetingUrlBanner',
    TargetingDomainCampaign = 'TargetingDomainCampaign',
    TargetingContentCategoryCampaign = 'TargetingContentCategoryCampaign',
    TargetingContentCategoryBanner = 'TargetingContentCategoryBanner',
    TargetingVisibilityCampaign = 'TargetingVisibilityCampaign',
    TargetingVisibilityBanner = 'TargetingVisibilityBanner',
    TargetingProviderCampaign = 'TargetingProviderCampaign',
    TargetingProviderBanner = 'TargetingProviderBanner',
    TargetingTvChannelCampaign = 'TargetingTvChannelCampaign',
    TargetingProgrammaticTvChannelCampaign = 'TargetingProgrammaticTvChannelCampaign',
    TargetingTvChannelBanner = 'TargetingTvChannelBanner',
    TargetingProgrammaticTvChannelBanner = 'TargetingProgrammaticTvChannelBanner',
    TargetingCitiesCampaign = 'TargetingCitiesCampaign',
    TargetingCitiesBanner = 'TargetingCitiesBanner',
    TargetingStatesCampaign = 'TargetingStatesCampaign',
    TargetingStatesBanner = 'TargetingStatesBanner',
    TargetingCountriesCampaign = 'TargetingCountriesCampaign',
    TargetingCountriesBanner = 'TargetingCountriesBanner',
    TargetingLanguagesCampaign = 'TargetingLanguagesCampaign',
    TargetingLanguagesBanner = 'TargetingLanguagesBanner',
    TargetingWeatherCampaign = 'TargetingWeatherCampaign',
    TargetingWeatherBanner = 'TargetingWeatherBanner',
    TargetingWifiCampaign = 'TargetingWifiCampaign',
    TargetingWifiBanner = 'TargetingWifiBanner',
    TargetingInterstitialCampaign = 'TargetingInterstitialCampaign',
    TargetingInterstitialBanner = 'TargetingInterstitialBanner',
    TargetingVideoPositionCampaign = 'TargetingVideoPositionCampaign',
    TargetingVideoPositionBanner = 'TargetingVideoPositionBanner',
    TargetingVideoPlayerCampaign = 'TargetingVideoPlayerCampaign',
    TargetingVideoPlayerBanner = 'TargetingVideoPlayerBanner',
    TargetingIasWebAppCampaign = 'TargetingIasWebAppCampaign',
    TargetingAdsquareCampaign = 'TargetingAdsquareCampaign',
    TargetingCustomSiteContextualCampaign = 'TargetingCustomSiteContextualCampaign',
    TargetingOdcContextualCampaign = 'TargetingOdcContextualCampaign',
    TargetingDoohLocationCampaign = 'TargetingDoohLocationCampaign',
    TargetingDoohLocationBanner = 'TargetingDoohLocationBanner',
    TargetingOttoPntaCampaign = 'TargetingOttoPntaCampaign',
    TargetingPostalCodeCampaign = 'TargetingPostalCodeCampaign',
    TargetingPolygonCampaign = 'TargetingPolygonCampaign',
    TargetingWeischerAudiencesCampaign = 'TargetingWeischerAudiencesCampaign',

    // Audience Attribute
    AudienceAttributes = 'AudienceAttributes',
    AudienceAttributeForm = 'AudienceAttributeForm',
    AudienceAttributePermissions = 'AudienceAttributePermissions',

    // Audience Attribute Peculiarity
    AudienceAttributePeculiarities = 'AudienceAttributePeculiarities',
    AudienceAttributePeculiarityForm = 'AudienceAttributePeculiarityForm',

    // Reporting
    DspReporting = 'Reporting',
    FootfallReporting = 'FootfallReporting',
    UniqueImpressionsReporting = 'UniqueImpressionsReporting',
    PTVReporting = 'PTVReporting',
    ViewabilityReporting = 'ViewabilityReporting',

    // Inventory Discovery
    InventoryDiscovery = 'InventoryDiscovery',

    // Deal Management
    DealManagementCreation = 'DealManagementCreation',
    DealManagementSettings = 'DealManagementSettings',
    DealManagementDeals = 'DealManagementDeals',
    DealManagementArchivedDeals = 'DealManagementArchivedDeals',

    // Seat
    Seats = 'Seats',

    // Footfall Settings
    OrderFootfallSettings = 'OrderFootfallSettings',
    BannerFootfallSettings = 'BannerFootfallSettings',

    // Adblock Detection
    AdBlockDetected = 'AdBlockDetected',
}

const manualLinkIds: Record<ManualLinkKey, number> = {
    [ManualLinkKey.HomePage]:  13,

    // Advertiser
    [ManualLinkKey.AdvertiserForm]:  229,

    // Advertiser / Tracking Pages
    [ManualLinkKey.AdvertiserTrackingPages]:  199,
    [ManualLinkKey.AdvertiserTrackingPageGeneralSettings]:  1127,
    [ManualLinkKey.AdvertiserTrackingPageLinks]:  1133,

    // Tracking Spots / Tracking Spots
    [ManualLinkKey.TrackingPageTrackingSpots]:  1130,
    [ManualLinkKey.TrackingPageTrackingSpotGeneralSettings]:  1130,

    // BannerPool
    [ManualLinkKey.BannerPools]:  232,
    [ManualLinkKey.BannerPoolForm]:  232,
    [ManualLinkKey.BannerPoolBannerForm]:  995,

    // Order
    [ManualLinkKey.OrderCreationGeneralSettings]:  1025,
    [ManualLinkKey.OrderSettingsGeneralSettings]:  1028,
    [ManualLinkKey.OrderBudget]:  2729,
    [ManualLinkKey.OrderRuntimeBudget]:  2732,
    [ManualLinkKey.OrderEvenDelivery]:  2735,

    // Campaign
    [ManualLinkKey.RedirectCampaignGeneralSettings]:  7009,
    [ManualLinkKey.CampaignTypeGeneralSettingsRuntime]:  3458,
    [ManualLinkKey.CampaignBudget]:  3461,
    [ManualLinkKey.CampaignEvenDelivery]:  3467,
    [ManualLinkKey.CampaignOptimization]:  3470,
    [ManualLinkKey.ViewabilityMeasurement]:  15327,

    // Campaign / Bulk Edit
    [ManualLinkKey.CampaignBulkEdit]:  1067,

    // Campaign / Third Party Cost
    [ManualLinkKey.CampaignThirdPartyCostForm]:  266,

    // Campaign / Deals
    [ManualLinkKey.CampaignDeals]:  269,

    // Campaign / Bid Factors
    [ManualLinkKey.CampaignBidFactors]:  272,

    // Campaign / Tracking Pages
    [ManualLinkKey.CampaignTrackingPages]:  275,

    // Banner
    [ManualLinkKey.BannerNativeType]:  2180,
    [ManualLinkKey.BannerNativeSpecs]:  2945,
    [ManualLinkKey.BannerImageType]:  2286,
    [ManualLinkKey.BannerRedirectType]:  2297,
    [ManualLinkKey.BannerRichMediaType]:  2304,
    [ManualLinkKey.BannerVastType]:  2311,
    [ManualLinkKey.BannerVastWrapperType]:  2319,
    [ManualLinkKey.BannerSwitchInType]:  2324,
    [ManualLinkKey.BannerSwitchInXxlType]:  2337,
    [ManualLinkKey.BannerSwitchInXxlAnimatedType]:  2346,
    [ManualLinkKey.BannerHtml5Type]:  5456,
    [ManualLinkKey.BannerAudioType]:  6203,
    [ManualLinkKey.BannerVideoType]:  6199,
    [ManualLinkKey.BannerDoohType]:  6201,
    [ManualLinkKey.BannerBumperAdType]:  8205,
    [ManualLinkKey.BannerDeprecatedAtvSpotType]:  8559,
    [ManualLinkKey.BannerPtvAdSpotType]:  9620,

    // Targeting
    [ManualLinkKey.TargetingTvTargetingCampaign]:  3807,
    [ManualLinkKey.TargetingTvTargetingBanner]:  3819,
    [ManualLinkKey.TargetingTaggingCampaign]:  1407,
    [ManualLinkKey.TargetingTaggingBanner]:  2026,
    [ManualLinkKey.TargetingSomTargetingCampaign]:  1410,
    [ManualLinkKey.TargetingSomTargetingBanner]:  2002,
    [ManualLinkKey.TargetingFrequencyCappingCampaign]:  1414,
    [ManualLinkKey.TargetingFrequencyCappingBanner]:  1990,
    [ManualLinkKey.TargetingGraphCappingCampaign]:  6719,
    [ManualLinkKey.TargetingGraphCappingBanner]:  6722,
    [ManualLinkKey.TargetingAnyIdFrequencyCampaign]:  15690,
    [ManualLinkKey.TargetingAnyIdFrequencyBanner]:  15697,
    [ManualLinkKey.TargetingProfileCampaign]:  1417,
    [ManualLinkKey.TargetingProfileBanner]:  2005,
    [ManualLinkKey.TargetingOperatingSystemCampaign]:  1420,
    [ManualLinkKey.TargetingOperatingSystemBanner]:  1980,
    [ManualLinkKey.TargetingBrowserCampaign]:  1423,
    [ManualLinkKey.TargetingBrowserBanner]:  1986,
    [ManualLinkKey.TargetingAudioCampaign]:  1425,
    [ManualLinkKey.TargetingAudioBanner]:  1971,
    [ManualLinkKey.TargetingTimeCampaign]:  1427,
    [ManualLinkKey.TargetingTimeBanner]:  2044,
    [ManualLinkKey.TargetingAuctionTypeCampaign]:  1429,
    [ManualLinkKey.TargetingAuctionTypeBanner]:  1977,
    [ManualLinkKey.TargetingSspCampaign]:  1431,
    [ManualLinkKey.TargetingSspAvailableSsps]:  3599,
    [ManualLinkKey.TargetingPlattformCampaign]:  1434,
    [ManualLinkKey.TargetingPlattformBanner]:  2008,
    [ManualLinkKey.TargetingUrlCampaign]:  1436,
    [ManualLinkKey.TargetingUrlBanner]:  2029,
    [ManualLinkKey.TargetingDomainCampaign]:  3617,
    [ManualLinkKey.TargetingContentCategoryCampaign]:  1438,
    [ManualLinkKey.TargetingContentCategoryBanner]:  1993,
    [ManualLinkKey.TargetingVisibilityCampaign]:  1442,
    [ManualLinkKey.TargetingVisibilityBanner]:  2017,
    [ManualLinkKey.TargetingProviderCampaign]:  1444,
    [ManualLinkKey.TargetingProviderBanner]:  2011,
    [ManualLinkKey.TargetingTvChannelCampaign]:  1446,
    [ManualLinkKey.TargetingTvChannelBanner]:  2014,
    [ManualLinkKey.TargetingProgrammaticTvChannelCampaign]:  1446,
    [ManualLinkKey.TargetingProgrammaticTvChannelBanner]:  2014,
    [ManualLinkKey.TargetingCitiesCampaign]:  1448,
    [ManualLinkKey.TargetingCitiesBanner]:  2023,
    [ManualLinkKey.TargetingStatesCampaign]:  1450,
    [ManualLinkKey.TargetingStatesBanner]:  1983,
    [ManualLinkKey.TargetingCountriesCampaign]:  1452,
    [ManualLinkKey.TargetingCountriesBanner]:  1999,
    [ManualLinkKey.TargetingLanguagesCampaign]:  1454,
    [ManualLinkKey.TargetingLanguagesBanner]:  2020,
    [ManualLinkKey.TargetingWeatherCampaign]:  1456,
    [ManualLinkKey.TargetingWeatherBanner]:  2032,
    [ManualLinkKey.TargetingWifiCampaign]:  1458,
    [ManualLinkKey.TargetingWifiBanner]:  2035,
    [ManualLinkKey.TargetingInterstitialCampaign]:  1460,
    [ManualLinkKey.TargetingInterstitialBanner]:  1996,
    [ManualLinkKey.TargetingVideoPositionCampaign]:  1462,
    [ManualLinkKey.TargetingVideoPositionBanner]:  2038,
    [ManualLinkKey.TargetingVideoPlayerCampaign]:  1464,
    [ManualLinkKey.TargetingVideoPlayerBanner]:  2041,
    [ManualLinkKey.TargetingIasWebAppCampaign]:  3937,
    [ManualLinkKey.TargetingAdsquareCampaign]:  4175,
    [ManualLinkKey.TargetingCustomSiteContextualCampaign]:  6241,
    [ManualLinkKey.TargetingOdcContextualCampaign]:  6483,
    [ManualLinkKey.TargetingDoohLocationCampaign]:  8352,
    [ManualLinkKey.TargetingDoohLocationBanner]:  8354,
    [ManualLinkKey.TargetingOttoPntaCampaign]:  9202,
    [ManualLinkKey.TargetingPostalCodeCampaign]:  10001,
    [ManualLinkKey.TargetingPolygonCampaign]: 9364,
    [ManualLinkKey.TargetingWeischerAudiencesCampaign]:  15181,

    // Audience Attribute
    [ManualLinkKey.AudienceAttributes]:  180,
    [ManualLinkKey.AudienceAttributeForm]:  212,
    [ManualLinkKey.AudienceAttributePermissions]:  218,

    // Audience Attribute Peculiarity
    [ManualLinkKey.AudienceAttributePeculiarities]:  215,
    [ManualLinkKey.AudienceAttributePeculiarityForm]:  215,

    // Reporting
    [ManualLinkKey.DspReporting]:  6707,

    // Footfall Reporting
    [ManualLinkKey.FootfallReporting]:  6709,

    // Unique Impressions Reporting
    [ManualLinkKey.UniqueImpressionsReporting]:  11425,

    // PTV Reporting
    [ManualLinkKey.PTVReporting]:  11951,

    // Viewability Reporting
    [ManualLinkKey.ViewabilityReporting]:  15148,

    // Inventory Discovery
    [ManualLinkKey.InventoryDiscovery]:  192,

    // Deal Management
    [ManualLinkKey.DealManagementCreation]:  306,
    [ManualLinkKey.DealManagementSettings]:  5412,
    [ManualLinkKey.DealManagementDeals]:  315,
    [ManualLinkKey.DealManagementArchivedDeals]:  2646,

    // Seat
    [ManualLinkKey.Seats]:  4149,

    // Footfall Settings
    [ManualLinkKey.OrderFootfallSettings]:  6701,
    [ManualLinkKey.BannerFootfallSettings]:  6704,

    [ManualLinkKey.AdBlockDetected]:  8617,
};

export {manualLinkIds, ManualLinkKey};
