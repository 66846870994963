import {CampaignTypes} from '../../campaign/campaign-types';
import {FilterTypes} from '../types/filter.types';

const CAMPAIGN_DEFAULT_FILTERS_BY_CAMPAIGN_TYPE: {[campaignType: string]: Array<FilterTypes>} = {
    [CampaignTypes.Pep]: [FilterTypes.CAMPAIGN_PEP_SSP],
    [CampaignTypes.DigitalOutOfHome]: [FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE],
    [CampaignTypes.AddressableTv]: [FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE],
    [CampaignTypes.AddressableTVSpotBundle]: [FilterTypes.CAMPAIGN_ATV_SPOT_BUNDLE_SSP],
};

const CAMPAIGN_DEFAULT_FILTERS: Array<FilterTypes> = [
    FilterTypes.CAMPAIGN_PEP_SSP,
    FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE,
    FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE,
    FilterTypes.CAMPAIGN_ATV_SPOT_BUNDLE_SSP,
];

export {CAMPAIGN_DEFAULT_FILTERS_BY_CAMPAIGN_TYPE, CAMPAIGN_DEFAULT_FILTERS};
