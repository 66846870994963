<libs-card [noMargin]="true" class="info-card" (click)="handleClick()">
    <div class="info-card__body"  [class.info-card__body--selected]="selected()" [class.info-card__body--disabled]="disabled()" [attr.aria-selected]="selected()">
        <div class="info-card-container">
            @if(selected()) {
                <div class="info-card-container__check-icon"><mat-icon [color]="'primary'">check_circle</mat-icon></div>
            }
            <div class="info-card-container__icon">
                <ng-content select="[info-card-icon]"></ng-content>
            </div>
            <div class="info-card-container__text-container">
                <h3>{{title()}}</h3>
                <p>{{description()}}</p>
            </div>
        </div>
        @if(price()) {
            <div class="info-card-container__price">
                    <libs-filter-price [prefix]="pricePrefix()" [styles]="priceStyle()" [price]="price() || 0" />
            </div>
        }
    </div>
</libs-card>
