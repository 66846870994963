enum CampaignTypeConverterProcessStatus {
    New = 'New',
    Pending = 'Pending',
    Running = 'Running',
    Error = 'Error',
    Success = 'Success',
    Unsupported = 'Unsupported',
}

export {CampaignTypeConverterProcessStatus};
