import {FilterTypes} from '../types/filter.types';
import {GEO_TYPE_KEY, getAvailableGeoTypes} from '../types/geo-types';

/**
 * GEO filters possible geoType values.
 */
const GEO_TYPES: Array<string> = getAvailableGeoTypes();

function getApiTypeForCustomType<TFilterType extends FilterTypes>(type: TFilterType): TFilterType | string {
    let nativeType: FilterTypes | undefined;
    Object.keys(customTypes).forEach((key: string) => {
        customTypes[key].forEach((currentType: FilterTypes | string) => {
            if (currentType === type) {
                nativeType = key as FilterTypes;
            }
        });
    });

    if (GEO_TYPES.includes(type)) {
        return GEO_TYPE_KEY;
    }

    return nativeType ? nativeType : type;
}

/**
 * Defines the API type that should be used for each custom type.
 */
const customTypes: {[key: string]: Array<FilterTypes | string>} = {
    [FilterTypes.PROFILE]: [
        FilterTypes.AUDIO,
        FilterTypes.CONTENT_CATEGORY,
        FilterTypes.LANGUAGE,
        FilterTypes.PLATFORM,
        FilterTypes.TV_TARGETING_GROUP,
        FilterTypes.VISIBILITY,
        FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE,
        FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE,
        FilterTypes.WIFI,
        FilterTypes.INTERSTITIAL,
        FilterTypes.VIDEO_PLAYER,
        FilterTypes.VIDEO_POSITION,
        FilterTypes.WEATHER,
        FilterTypes.AUCTION_TYPE,
    ],
    [FilterTypes.TAGGING]: [
        FilterTypes.CLUSTER,
    ],
    [FilterTypes.SSP]: [
        FilterTypes.CAMPAIGN_PEP_SSP,
        FilterTypes.CAMPAIGN_ATV_SPOT_BUNDLE_SSP,
    ],
    [FilterTypes.DOMAIN]: [
        FilterTypes.TV_CHANNEL,
        FilterTypes.PROGRAMMATIC_TV_CHANNEL,
    ],
};

export {GEO_TYPES, getApiTypeForCustomType, customTypes};
