import {Campaign, RtbCampaign} from './rtb-campaign';
import {CampaignTypes} from './campaign-types';
import {ICloneable, IMergable} from '../types';
import {CpxMetric, IAddressableTvCampaign} from './campaign';

class AddressableTvSpotBundleCampaign extends RtbCampaign implements
    IAddressableTvCampaign,
    IMergable<Campaign>,
    ICloneable<AddressableTvSpotBundleCampaign> {

    private readonly _type: CampaignTypes = CampaignTypes.AddressableTVSpotBundle;
    private _cpxMetrics: Array<CpxMetric> = [];

    public get type(): CampaignTypes {
        return this._type;
    }

    get cpxMetrics(): Array<CpxMetric> {
        return this._cpxMetrics;
    }

    set cpxMetrics(value: Array<CpxMetric>) {
        this._cpxMetrics = value;
    }

    public merge(source: Campaign): void {
        super.merge(source);
        AddressableTvSpotBundleCampaign.fillAdditionalFields(this, source);
    }

    public clone(): AddressableTvSpotBundleCampaign {
        const newModel: AddressableTvSpotBundleCampaign = new AddressableTvSpotBundleCampaign(
            this.id,
            this.orderId,
            this.networkId,
            this.biddingStrategy,
            this.dailyBudget,
            this.deliveryTechnique,
            this.maxCpm,
            this.name,
            this.totalBudget,
        );
        newModel.merge(this);

        return newModel;
    }

    public static fillAdditionalFields(target: AddressableTvSpotBundleCampaign, source: Campaign): void {
        RtbCampaign.fillAdditionalFields(target, source);

        if ('cpxMetrics' in source) {
            target.cpxMetrics = ([] as Array<CpxMetric>).concat(source.cpxMetrics);
        }
    }
}

function isAddressableTvSpotBundleCampaign(campaign: unknown): campaign is AddressableTvSpotBundleCampaign {
    return campaign instanceof AddressableTvSpotBundleCampaign;
}

export {AddressableTvSpotBundleCampaign, isAddressableTvSpotBundleCampaign};
